body {
  background-color: #000;
}

.bg-gradient-goldcenter {
  background: linear-gradient(90deg, rgba(143,98,0,1) 0%, rgba(238,206,60,1) 50%, rgba(143,98,0,1) 100%);
}

.bg-gradient-dark {
  background: linear-gradient(180deg, rgb(50 50 50) 0%, rgb(0 0 0) 100%);
}
.bg-gradient-gold {
  background: linear-gradient(180deg, rgb(245, 167, 0) 0%, rgba(143,98,0,1) 100%);
}

.logo {
  height: 4.5rem;
}